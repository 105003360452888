import React, { useState, useRef } from 'react';
import classNames from 'classnames';

import { hooks } from '@moved/services';

import { Icon } from '../../../sondheim/components/Icon';
import { FilterTypeAhead, FilterMultiSelect, FilterSingleSelect, FilterDateSelect, FilterDateRangeSelect } from './types';
import CSS from './FilterSearch.module.scss';

export const FilterSearch = ({ children, list = [], active, title, onSelect, popOverClass, searchAction, activeItems = [], type = 'multiSelect', initial = false, single = false, className }) => {
  const { useOutsideClick } = hooks;

  if(!active) {
    switch(type) {
      case 'typeAhead':
        active = [];
        break;
      case 'multiSelect':
        active = [];
        break;
      case 'singleSelect':
        active = '';
        break;
      case 'dateSelect':
        active = '';
        break;
      case 'dateRangeSelect':
        active = [];
        break;
      default:
        active = [];
        break;
    }
  }

  // HOOKS
  const [ visible, setVisible ] = useState(initial);
  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef, () => setVisible(false));

  const toggleVisible = () => setVisible(!visible);

  return (
    <div ref={wrapperRef} className={classNames(CSS.wrapper, className)}>
      <div className={classNames(CSS.trigger, { 'filterSearchActive' : visible })} onClick={toggleVisible}>
        {children}
      </div>
      { visible && (
        <div className={CSS.menu}>
          <div className={classNames(CSS.popover, popOverClass)}>
            <div className={CSS.title_bar}>
              <h4>{title}</h4>
              <div onClick={e => setVisible(false)}>
                <Icon symbol={'Close'} library={'navigation'} size={'24px'} />
              </div>
            </div>
            {(function() {
              switch(type) {
                case 'typeAhead':
                  return (
                    <FilterTypeAhead
                      list={list}
                      setVisible={setVisible}
                      title={title}
                      popOverClass={popOverClass}
                      active={active}
                      setActive={onSelect}
                      single={single}
                      searchAction={searchAction}
                      activeItems={activeItems}
                    />
                  );
                case 'multiSelect':
                  return (
                    <FilterMultiSelect
                      list={list}
                      setVisible={setVisible}
                      title={title}
                      popOverClass={popOverClass}
                      active={active}
                      setActive={onSelect}
                      single={single}
                      searchAction={searchAction}
                    />
                  );
                case 'singleSelect':
                  return (
                    <FilterSingleSelect
                      list={list}
                      setVisible={setVisible}
                      title={title}
                      popOverClass={popOverClass}
                      active={active}
                      setActive={onSelect}
                    />
                  )
                case 'dateSelect':
                  return (
                    <FilterDateSelect
                      list={list}
                      setVisible={setVisible}
                      title={title}
                      popOverClass={popOverClass}
                      active={active}
                      setActive={onSelect}
                    />
                  )
                case 'dateRangeSelect':
                  return (
                    <FilterDateRangeSelect
                      list={list}
                      setVisible={setVisible}
                      title={title}
                      popOverClass={popOverClass}
                      active={active}
                      setActive={onSelect}
                    />
                  )
                default:
                  return null;
              };
            })()}
          </div>
        </div>
      )}
    </div>
  );

};
