import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { get } from 'lodash';

import { format, useUser } from '@moved/services';
import { Icon, Collapsible, FieldList, StatusPill, Notebox } from '@moved/ui';
import { taskTypes } from '@moved/product';

import { ApprovalActions } from '../../approvals/components/ApprovalActions';
import { getTenantEvent } from '../actions';
import { AdminCompleteTask } from './AdminCompleteTask';

import CSS from './styles/TaskDetails.module.scss';

// Map connecting task_type to taskable key
const taskableMap = {
  'addon-interests': 'addon_interests',
  'badge-upload': 'badge_upload_tasks',
  'book-movers': 'mover_booking_tasks',
  'book-movers-for-storage': 'mover_booking_tasks',
  'coi': 'coi_submissions',
  'deposit': 'security_deposit_tasks',
  'document-submission': 'document_submission_tasks',
  'happyco-inspection': 'happyco_inspection_tasks',
  'insurance': 'renters_insurance_tasks',
  'keys': 'key_pickup_tasks',
  'move-out-details': 'move_out_details_tasks',
  'move-out-inspection': 'building_reservation_tasks',
  'orientation': 'user_orientation_progresses',
  'pay-move-in-costs': 'pay_move_in_costs_tasks',
  'pet-registration': 'pet_registration_tasks',
  'petscreening-verification': 'petscreening_verification_tasks',
  'reserve': 'building_reservation_tasks',
  'utilities': 'user_utility_acknowledgements',
  'utility-setup': 'utility_setup_tasks',
  'vehicle-registration': 'vehicle_registration_tasks',
};

// Map connecting task_type to request key (for approvals)
const requestMap = {
  'coi': 'coi-uploads',
  'document-submission': 'document-submission-requests',
  'insurance': 'renters-insurance-policy-requests',
  'keys': 'key-pickup-appointment-requests',
  'move-out-inspection': 'building_reservation_tasks',
  'orientation': 'orientation-requests',
  'pay-move-in-costs': 'pay-move-in-costs-requests',
  'pet-registration': 'pet-registration-requests',
  'reserve': 'building-reservation-requests',
  'utility-setup': 'utility-setup-requests',
  'vehicle-registration': 'vehicle-registration-requests',
};

const getTaskCopy = (status) => {
  switch(status) {
    case 'in-progress':
    case 'action-required':
    case 'pending-approval':
      return 'The tenant has started but not completed this task.'
    case 'complete':
      return 'The tenant reviewed this task and indicated that it is complete. There are no details to show.'
    case 'not-started':
    case 'locked':
    default:
      return 'The tenant has not started this task.'
  }
};

export const TaskDetails = ({ task, tenantEvent }) => {
  const dispatch = useDispatch();
  const { hasAbilities } = useUser();
  const [active, setActive] = useState();

  const taskHelper = taskTypes[task.task_type];
  if(!taskHelper) return null;

  const taskables = get(tenantEvent, 'taskables', {});
  const taskable = get(taskables, taskableMap[task.task_type], [])
    .find(item => item.id === task.task_details_id);
  const artifacts = taskHelper.getArtifacts(tenantEvent)
    .filter(item => item.building_task_id === task.building_task_id);
  const overrides = taskHelper.getOverrides(tenantEvent)
    .filter(item => item.building_task_id === task.building_task_id);
  const activeRequest = taskHelper.getActiveRequest(taskable);

  const detailsDescription = getTaskCopy(task.status);

  return (
    <div className={classNames(CSS.box, { [CSS.box_active]: active })}>

      <div className={CSS.box_top} onClick={() => setActive(active => !active)}>

        <div className={CSS.top_left}>
          <div className={CSS.title}>
            <Icon symbol={task.icon_name} size='36px' />
            <h4>{task.title}</h4>
          </div>
        </div>

        <div className={CSS.top_right}>
          { task.status === 'complete' && (
            <div>
              {task.completed_at && format.date(task.completed_at)}
            </div>
          )}
          <div>
            <StatusPill status={task.status || 'not-started'}/>
          </div>
          <div>
            <Icon symbol='Chevron-right' library='navigation' className={CSS.arrow} />
          </div>
        </div>

      </div>

      <Collapsible open={active}>
        <div className={CSS.box_bottom}>
          {
            // if pending request, show request summary and approve/decline CTAs
            (activeRequest && activeRequest.status === 'pending-approval') ? (
              <div className='stackVertical-20 width-full'>
                <FieldList list={taskHelper.getRequestFields(activeRequest)} />
                <div className={classNames('stackHorizontal-12',CSS.actions)}>
                  <ApprovalActions
                    requestType={requestMap[task.task_type]}
                    requestId={activeRequest.id}
                    onSubmit={() => dispatch(getTenantEvent(tenantEvent.id))}
                  />
                </div>
                {(artifacts.length > 0) && (
                  <div className='stackVertical width-full'>
                    <Notebox
                      heading='Already approved'
                      color='blue'
                      icon={{ library: 'code', symbol: 'Info-circle' }}
                      className='marginBottom-0'
                    />
                    { artifacts.map((artifact,index) => (
                      <FieldList list={taskHelper.getArtifactFields(artifact)} key={artifact.id||index} />
                    ))}
                  </div>
                )}
              </div>
            // if artifacts, show artifact summaries
            ) : (artifacts.length > 0 || overrides.length > 0) ? (
              <div className='stackVertical-20 width-full'>
                { artifacts.map((artifact,index) => (
                  <FieldList list={taskHelper.getArtifactFields(artifact)} key={artifact.id||index} />
                ))}
                { overrides.map((override, index) => (
                  <FieldList list={taskHelper.getOverrideFields(override)} key={override.id||index} />
                ))}
              </div>
            // else if non-draft request, show request summary and completion CTA
            ) : (activeRequest && activeRequest.status === 'rejected') ? (
              <div className='stackVertical-20 width-full'>
                <FieldList list={taskHelper.getRequestFields(activeRequest)} />
                { hasAbilities(`ProxyEdit${format.kebabToPascal(task.task_type)}`) && (
                  <AdminCompleteTask task={task} message={detailsDescription} />
                )}
              </div>
            // else if not completed show completion CTA
            ) : (task.status !== 'complete' && hasAbilities(`ProxyEdit${format.kebabToPascal(task.task_type)}`)) ? (
              <AdminCompleteTask task={task} message={detailsDescription} />
            // else show taskable summary
            ) : (taskable && !['not-started','in-progress'].includes(task.status)) ? (
              <FieldList list={taskHelper.getSummaryFields(taskable)} emptyContent={detailsDescription} />
            // else show default text
            ) : (
              <>
                <div className={'contentSecondary marginTop-8'}>
                  { detailsDescription }
                </div>
                {/* PYMIC task is special because it has data from the partner
                    that we want to show even before the customer has submitted a request for approval or before any artifacts
                    have been created */
                taskable && task.task_type === 'pay-move-in-costs' && (
                  <FieldList list={taskHelper.getSummaryFields(taskable)} />
                )}
              </>
            )
          }
        </div>
      </Collapsible>

    </div>
  );
};
