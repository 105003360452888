import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

import { useLayer, useScroller } from '@moved/services';
import { Collapsible } from '@moved/ui';

import { Button } from '../controls/Button';
import CSS from './Drawer.module.scss';

export const Drawer = ({ children }) => {
  const { id, options={}, close } = useLayer();
  const Scroller = useScroller();
  useEffect(() => {
    return(() => Scroller.set(window)); // cleanup the scroller ref on unmount
  },[]); // eslint-disable-line

  const handleBackgroundClick = (e) => {
    if(!Scroller.ref.current || Scroller.ref.current.contains?.(e.target)) return;
    if(options.closeOnBackgroundClick) close();
  };

  return (
    <div id={id} className={CSS.drawerWrapper} onClick={handleBackgroundClick}>
      <div className={CSS.drawer} ref={Scroller.ref}>
        { children }
      </div>
    </div>
  );
};

export const DrawerHeader = ({ title, description, className }) => {
  const Layer = useLayer();
  const { ref } = useScroller();
  const [isCollapsed, setIsCollapsed] = useState();

  // use scroller service and useEffect to attach event listeners
  // to the parent element to update the header display as the user
  // scrolls up and down the content
  useEffect(() => {
    const scroller = ref.current;
    const handleScroll = (e) => {
      if(Math.sign(e.deltaY) === 1) { // if scrolling down
        const shouldCollapse = !isCollapsed && ref.current.scrollTop > 10; // scroll 10 before it collapses
        if(shouldCollapse) return setIsCollapsed(true);
      }
      if(Math.sign(e.deltaY) === -1) { // if scrolling up
        const shouldExpand = isCollapsed && ref.current.scrollTop === 0; // must hit the top before re-expanding
        if(shouldExpand) return setIsCollapsed(false);
      }
    };
    scroller.addEventListener('wheel', handleScroll);
    return () => scroller.removeEventListener('wheel', handleScroll);
  },[ref,isCollapsed]);

  return (
    <div className={classNames(CSS.header,{[CSS.collapsed]:isCollapsed},className)}>
      <div className={CSS.leftColumn}>
        <div className={classNames('headingS contentPrimary',CSS.title,CSS.mini)}>{ title }</div>
        <Collapsible open={!isCollapsed}>
          <div className={CSS.title}>
            <div className='displayS contentPrimary'>{ title }</div>
            <div className='labelS contentSecondary'>{ description }</div>
          </div>
        </Collapsible>
      </div>
      <div className={CSS.rightColumn}>
        <Button
          icon={{ symbol: 'Close', library: 'navigation'}}
          color='secondary'
          size='small'
          onClick={() => Layer.close()}
        />
      </div>
    </div>
  );
};

export const DrawerContent = ({ className, children }) => (
  <div className={classNames(CSS.content, className)}>{ children }</div>
);

export const DrawerActions = ({ className, children }) => (
  <div className={classNames(CSS.actions, className)}>
    { children }
  </div>
);
