import React from 'react';
import { useDispatch } from 'react-redux';

import { useModal, useUser } from '@moved/services';

import { Action } from './Action';
import { canAddCoupon } from '../../helpers';
import { addCoupon } from '../../actions';
import { useAddCouponPending } from '../../actions/selectors';

import { AddCouponModal } from '../../../common';

export const AddCoupon = ({ order, move, ...props }) => {
  // hooks
  const { open } = useModal();
  const { hasAbilities } = useUser();
  const dispatch = useDispatch();
  // early exit
  if(!canAddCoupon(order) || !hasAbilities('CreateOrderAdjustments')) return null;

  const onAdd = (data) => dispatch(addCoupon(order.id, data)); // returns promise

  // create the action button
  const openModal = () => open(<AddCouponModal move={move} addHandler={onAdd} addPendingSelector={useAddCouponPending} />);
  const icon = {
    library: 'navigation',
    symbol: 'Plus',
  }
  return <Action onClick={openModal} icon={icon} {...props}>Add</Action>;
};
