import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { get, noop } from 'lodash';

import { useModal, useNotify, format, useUser } from '@moved/services';
import { ConfirmationModal } from '@moved/ui';
import { MoveStepTypeIcon } from '@moved/product';

import { createReservation, createKeyPickup, getTenantEvents, rescheduleReservation, rescheduleKeyPickup, getPartnerCalendar, } from '../actions';
import { useReservationPending, useKeyPickupPending } from '../actions/selectors';

import { DynamicForm, AnimatedCheckmark } from '@moved/ui';
import avaCSS from '../styles/SlotEdit.module.scss';

export const SlotEdit = ({ slot, hide, calendarId, type, closeBtn }) => {
  // hooks
  const dispatch = useDispatch();
  const Modal = useModal();
  const Notify = useNotify();
  const { hasAbilities } = useUser();
  // state
  const [submitted, setSubmitted] = useState(false);
  const shouldUseBuildingReservation = ['reserve','move-out-inspection'].includes(type);

  const createAppointment = shouldUseBuildingReservation ? createReservation : createKeyPickup;
  const pendingSelector = shouldUseBuildingReservation ? useReservationPending : useKeyPickupPending;
  const pending = pendingSelector();

  const rescheduleAppointment = shouldUseBuildingReservation ? rescheduleReservation : rescheduleKeyPickup;

  const reschedule = (id,data) => dispatch(rescheduleAppointment(id,calendarId,{start_time: data.start_time}));

  // helper functions
  const handleSubmit = (data) => {
    let submitData = {
      ...data,
      building_calendar_id: calendarId,
    };
    delete submitData.unit_number;
    delete submitData.confirmation;
    dispatch(createAppointment(submitData)).then(() => {
      setSubmitted(true);
      hide();
      return dispatch(getPartnerCalendar(calendarId, moment(`${slot.date}T${slot.start}`).startOf('week').format('YYYY-MM-DD')))
    })
    .catch(error => {
      const errors = format.error(error);
      if(get(error,'response.status') === 409) {
        hide();
        return Modal.open(
          (
            (shouldUseBuildingReservation && hasAbilities('RescheduleBuildingReservations'))
            || (type === 'keys' && hasAbilities('RescheduleKeyPickupAppointments'))
          ) ? (
            <ConfirmationModal
              title={'This resident already has an appointment'}
              copy={`${errors.length > 0 && errors[0]}. If you proceed, the resident's other appointment will be canceled. Are you sure you wish to reschedule?`}
              cancelText={'Never mind'}
              confirmText={'Reschedule'}
              onConfirm={e =>
                reschedule(get(error,'response.data.errors[0].details.id'),submitData)
                  .then(resp => dispatch(getPartnerCalendar(calendarId, moment(`${slot.date}T${slot.start}`).startOf('week').format('YYYY-MM-DD'))))
              }
            />
          ) : (
            <ConfirmationModal
              title={'This resident already has an appointment'}
              cancelText={false}
              copy={`${errors.length > 0 && errors[0]}. You may not reschedule their appointment.`}
            />
          ),
          { sondheim: true },
        );
      } else {
        return Notify.error(errors);
      }
    });
  };

  const slotFields = [
    {
      label: 'Full name',
      type: 'typeAhead',
      name: 'tenant_event_id',
      action: (term) => dispatch(getTenantEvents(calendarId, {keywords: term, filter: 'recent_and_upcoming'}))
        .then(tenantEvents => tenantEvents.map(option => ({
          ...option,
          label: `${get(option,'tenant.firstname')} ${get(option,'tenant.lastname')}`,
          label_right: get(option,'lease.address.unit'),
          label_icon: (<MoveStepTypeIcon type={get(option,'move_step_type.label')} />),
        }))
      ),
      value: '',
      required: true,
      onChange: (input, option, setFieldValue) => { return setFieldValue('unit_number', get(option,'lease.address.unit') ? get(option,'lease.address.unit') : '', false); }
    },
    {
      label: 'Unit number',
      type: 'text',
      name: 'unit_number',
      value: '',
      readOnly: true,
    },
    {
      type: 'hidden',
      name: 'start_time',
      value: `${slot.date}T${slot.start}`,
    }
  ];

  return (
    <div className={avaCSS.slot_edit}>
      { submitted && (
        <div className={avaCSS.confirm}>
          <AnimatedCheckmark isChecked={true} fast={true} />
          <h4>Reservation added!</h4>
        </div>
      )}

      <div className={avaCSS.slot_edit_title}>
        <span>New reservation</span>
        <span className={avaCSS.date}>
          {moment(`${slot.date}T${slot.start}`).format('MMM D, YYYY | h:mm')}{moment(`${slot.date}T${slot.end}`).format(' - h:mm a')}
          {closeBtn && (<span className={avaCSS.close} onClick={e => hide()}>&times;</span>)}
        </span>
      </div>
      <div className={avaCSS.slot_edit_form}>
        <DynamicForm
          fields={slotFields}
          formStyle={'underline'}
          id="slot-form"
          onSubmit={handleSubmit}
        />
      </div>
      <div className={avaCSS.slot_edit_control}>
        <label
          className={'btn-gray flex-auto mr-10'}
          disabled={pending}
          onClick={pending ? noop : e => hide()}
        >
          Cancel
        </label>
        <label
          htmlFor="slot-form-submit"
          tabIndex="0"
          role="button"
          className={'btn-primary flex-auto ' + (pending  ? ' loading' : '')}
          disabled={pending}
        >
          Create
        </label>
      </div>
    </div>
  );
}
