import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { get } from 'lodash';

import { DynamicForm, AtomSpinner } from '@moved/ui';
import { useModal, useNotify, format, useUser } from '@moved/services'

import { getBuildings } from '../../../activity/moves/actions';
import { useBuildings, useBuildingsPending } from '../../../activity/moves/actions/selectors';

import { getClientSearch } from '../../clients/actions';
import { useClientSearch, useClientSearchPending } from '../../clients/actions/selectors';

import { createAdmin } from '../actions';
import { useCreateAdminPending } from '../actions/selectors';

import CSS from './styles/CreateAdminModal.module.scss';

export const CreateAdminModal = () => {
  // hooks
  const dispatch = useDispatch();
  const { close } = useModal();
  const Notify = useNotify();
  const history = useHistory();
  const location = useLocation();
  const { hasAbilities } = useUser();
  // redux
  const pending = useCreateAdminPending();
  const buildings = useBuildings();
  const buildingsPending = useBuildingsPending();
  const clients = useClientSearch();
  const clientsPending = useClientSearchPending();
  // state
  const [partner, setPartner] = useState(false);


  const handleSubmit = (data) => {
    const cleanData = {...data};
    delete cleanData.partner_id;
    dispatch(createAdmin(cleanData))
      .then(resp => {
        close(false);
        history.push({
          pathname: `/admin/admins/${resp.id}`,
          state: {
            from: `${location.pathname}${location.search}`,
          }
        });
      })
      .catch(error => Notify.error(format.error(error)));
  };

  useEffect(() => {
    dispatch(getClientSearch({ limit: 500 }));
  // eslint-disable-next-line
  },[]);

  useEffect(() => {
    if(partner) {
      dispatch(getBuildings(
        {
          partner_ids: partner.value,
          limit: 500,
        }
      ));
    }
  // eslint-disable-next-line
  },[partner]);

  const slotFields = [
    {
      label: 'First name',
      type: 'text',
      name: 'firstname',
      required: true,
      half: true,
    },
    {
      label: 'Last name',
      type: 'text',
      name: 'lastname',
      required: true,
      half: true,
    },
    {
      label: 'Email address',
      type: 'email',
      name: 'email',
      required: true,
    },
    {
      label: 'Client',
      type: 'select',
      name: 'partner_id',
      placeholder: 'Select a client...',
      isMulti: false,
      required: true,
      onChange: (value, setFieldValue) => {
        setPartner(value);
        return setFieldValue('building_ids', [], false);
      },
      options: get(clients,'activeSet').map(client => ({
        label: `${client.name}`,
        value: client.id
      })),
    },
    {
      label: 'Assigned properties',
      type: 'select',
      name: 'building_ids',
      placeholder: !partner ? 'You must select a partner before selecting properties' : `Select at least one ${partner.label} property...`,
      isMulti: true,
      required: true,
      readOnly: !partner || buildingsPending,
      options: buildings.map(building => ({
        label: `${building.name}`,
        value: building.id
      })),
    },
    {
      label: 'Send email invitation',
      placeholder: 'Send email invitation',
      name: 'send_invite',
      type: hasAbilities('CreateAdminInvites') ? 'checkbox': 'hidden',
      value: false,
    },
  ];

  if(clientsPending) return (
    <div className={CSS.creation}>
      <div className={CSS.title}>
        <h3>Create admin</h3>
      </div>
      <AtomSpinner />
    </div>
  );

  return (
    <div className={CSS.creation}>
      <div className={CSS.title}>
        <h3>Create admin</h3>
      </div>
      <div className={CSS.form}>
        <DynamicForm
          fields={slotFields}
          formStyle={'underline'}
          id="create-admin-form"
          onSubmit={handleSubmit}
        />
      </div>
      <div className={CSS.controls}>
        <label
          className={'btn-gray mr-10'}
          disabled={pending}
          onClick={e => close(false)}
        >
          Cancel
        </label>
        <label
          htmlFor="create-admin-form-submit"
          tabIndex="0"
          role="button"
          className={'btn-primary ' + (pending  ? ' loading' : '')}
          disabled={pending}
        >
          Create
        </label>
      </div>
    </div>
  );
}
