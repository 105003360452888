import React from 'react';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import classNames from 'classnames';

import { Icon } from '@moved/ui';
import { format, useModal, useUser } from '@moved/services';

import { AddCouponModal, AddDiscountModal, FieldDisplay } from '../../common';

import { addCoupon } from '../actions';
import { useAddCouponPending } from '../actions/selectors';

import CSS from '../styles/MoveDetailsPreBooking.module.scss';

const Adjustment = ({ adjustment }) => {
  const fields = [
    {
      label: 'Amount',
      value: format.currency(adjustment.cents),
    },
    {
      label: 'Provided by',
      value: get(adjustment, 'sponsor.name'),
    },
  ];
  return (
    <div className={CSS.adjustment}>
      { fields.map(field => (<FieldDisplay key={field.label} field={field} />)) }
    </div>
  );
}

export const MoveDetailsUnclaimedAdjustments = ({ move }) => {
  // hooks
  const { open } = useModal();
  const dispatch = useDispatch();
  const { hasAbilities, Can } = useUser();

  // early exit
  if(!hasAbilities('ReadCoupons')) return null;

  const { unclaimed_order_adjustments=[] } = move;

  const onAdd = (data) => dispatch(addCoupon(move.id,data));
  const addCouponModal = () => open(<AddCouponModal move={move} addHandler={onAdd} addPendingSelector={useAddCouponPending}/>);
  const addDiscountModal = () => open(<AddDiscountModal addHandler={onAdd} addPendingSelector={useAddCouponPending}/>);

  // note: can't use shared order helper logic because there is no order yet :(
  const existingAdjustments = get(move,'unclaimed_order_adjustments',[]);
  const hasCoupon = existingAdjustments.find(adjustment => get(adjustment, 'sponsor.is_partner'));
  const hasDiscount = existingAdjustments.find(adjustment => !get(adjustment, 'sponsor.is_partner'));
  const canAddCoupon = !hasCoupon && get(move,'move_steps.length') > 0;

  return (
    <div className={CSS.section}>
      <div className={classNames(CSS.row,CSS.top)}>
        <div className={CSS.title}>
          <h3>Pending { canAddCoupon && 'Coupons &'} Discounts</h3>
        </div>
        <div className={CSS.actions}>
          <Can I="CreateOrderAdjustments">
            { canAddCoupon && (
              <div className={classNames(CSS.action,CSS.action_btn)} onClick={addCouponModal}>
                <Icon symbol={'Ticket'} library={'shopping'} size={'16px'} />
                <span>Add Coupon</span>
              </div>
            )}
            { !hasDiscount && (
              <div className={classNames(CSS.action,CSS.action_btn)} onClick={addDiscountModal}>
                <Icon symbol={'Price#1'} library={'shopping'} size={'16px'} />
                <span>Add Discount</span>
              </div>
            )}
          </Can>
        </div>
      </div>
      <div className={classNames(CSS.row,CSS.bottom)}>
        { unclaimed_order_adjustments.length ? unclaimed_order_adjustments.map(adjustment => (
          <Adjustment adjustment={adjustment} key={adjustment.id} />
        )) : (
          <div className={CSS.none}>No { canAddCoupon && 'coupons or'} discounts have been applied</div>
        )}
      </div>
    </div>
  );
};
