import React from 'react';
import { useModal, useUser } from '@moved/services';

import { Action } from './Action';
import { ResolvePaymentIssueModal } from '../modals/ResolvePaymentIssueModal';
import { canResolvePayment } from '../../helpers';

export const ResolvePaymentIssue = ({ order, ...props }) => {
  // hooks
  const Modal = useModal();
  const { hasAbilities } = useUser();
  // early exit
  if(!canResolvePayment(order) || !hasAbilities('CreatePayments')) return null;
  // create the action button
  const openModal = () => Modal.open(<ResolvePaymentIssueModal order={order} />);
  const icon = {
    library: 'shopping',
    symbol: 'Credit-card',
  };
  return <Action onClick={openModal} icon={icon} {...props}>Resolve Payment Issue</Action>;
};
