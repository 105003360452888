import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import * as Yup from 'yup';
import classNames from 'classnames';
import { get } from 'lodash';

import { DynamicForm, Icon, ClipboardWrapper } from '@moved/ui';
import { useUser, useModal, useNotify, format } from '@moved/services'

import { createDemoTenants } from '../actions';
import { useCreateDemoTenantsPending } from '../actions/selectors';

import CSS from './styles/CreateDemoTenantModal.module.scss';

export const CreateDemoTenantModal = ({ property }) => {
  // hooks
  const dispatch = useDispatch();
  const { close } = useModal();
  const Notify = useNotify();
  const history = useHistory();
  const { user } = useUser();

  // redux
  const pending = useCreateDemoTenantsPending();
  // state
  const [complete, setComplete] = useState(false);
  const [keywords, setKeywords] = useState();
  const [createdTenants, setCreatedTenants] = useState();

  const handleSubmit = (data) => {
    const formatData = { ...data };
    formatData.demo_tenants = formatData.demo_tenants.map(tenant => `${tenant.first} ${tenant.last}`);
    dispatch(createDemoTenants(property.id, formatData))
      .then(resp => {
        setCreatedTenants(resp);
        setKeywords(`${formatData.email_prefix}+${formatData.email_suffix}`);
        return setComplete(true);
      })
      .catch(error => Notify.error(format.error(error)));
  };

  const handleMovesNavigation = e => {
    e.preventDefault();
    close(false);
    history.push(`/admin/moves?keywords=${encodeURIComponent(keywords)}`);
  };

  const parsedEmail = user.email.split('@');
  const parsedName = parsedEmail[0].split('+')[0];

  let suffix = property.slug || '';

  const indexes = [];

  for (let idx = 0; idx < suffix.length; idx++) {
    if (suffix[idx] === '-') {
      indexes.push(idx);
    }
  }

  if (indexes.length > 1) suffix = suffix.substr(0,indexes[1])

  const slotFields = [

    {
      label: 'Email prefix',
      type: 'text',
      name: 'email_prefix',
      value: parsedName,
      half: true,
      required: true,
    },
    {
      label: 'Email suffix',
      type: 'text',
      name: 'email_suffix',
      value: suffix,
      half: true,
      required: true,
    },
    {
      label: 'Email domain',
      type: 'text',
      name: 'email_domain',
      value: parsedEmail[1],
      required: true,
    },
    {
      label: 'Tenants',
      name: 'demo_tenants',
      type: 'array',
      minimum: 1,
      value: [{first: user.firstname, last: user.lastname}],
      fieldInfo: {
        first: {
          label: 'First name',
          type: 'text',
        },
        last: {
          label: 'Last name',
          type: 'text',
        },
      },
    },
    {
      label: 'Unit',
      type: 'text',
      name: 'unit',
      required: true,
      half: true,
      value: process.env.REACT_APP_ENV_NAME === 'prod' ? '999Z' : '21B',
    },
    {
      label: 'Move direction',
      type: 'select',
      isMulti: false,
      name: 'direction',
      required: true,
      half: true,
      value: get(property,'building_task_lists[0].move_step_type.label'),
      options: get(property,'building_task_lists',[]).map(list => ({
        label: list.move_step_type.display_name,
        value: list.move_step_type.label,
      })),
    },
  ];

  const validation = Yup.object().shape({
    demo_tenants: Yup.array().of(
      Yup.object().shape({
        first: Yup.string().required("Required"),
        last: Yup.string().required("Required"),
      })
    ),
  });

  return !complete
    ? (
      <div className={CSS.creation}>
        <div className={CSS.title}>
          <h3>Create demo tenants for {property.name}</h3>
        </div>
        <div className={CSS.form}>
          <DynamicForm
            fields={slotFields}
            formStyle={'underline'}
            id="create-admin-form"
            validation={validation}
            onSubmit={handleSubmit}
          />
        </div>
        <div className={CSS.controls}>
          <label
            className={'btn-gray mr-10'}
            disabled={pending}
            onClick={e => close(false)}
          >
            Cancel
          </label>
          <label
            htmlFor="create-admin-form-submit"
            tabIndex="0"
            role="button"
            className={'btn-primary ' + (pending  ? ' loading' : '')}
            disabled={pending}
          >
            Create tenants
          </label>
        </div>
      </div>
    )
    : (
      <div className={CSS.creation}>
        <div className={CSS.title}>
          <h3>Tenants created</h3>
        </div>
        <div className={CSS.form}>
          {createdTenants.map(tenant => (
            <div className={CSS.demo_tenant} key={`tenant_${tenant.tenant_event_id}`}>
              <div className={CSS.tenant_name}>
                <h4>{tenant.first} {tenant.last}</h4>
                <Link to={`/admin/moves/${tenant.tenant_event_id}`} target="_blank" rel="noopener noreferrer">
                  ( View move in new tab )
                </Link>
              </div>
              <div>{tenant.email}</div>
              <div>
                <ClipboardWrapper text={`${process.env.REACT_APP_CONSUMER_APP_URL}${tenant.signup_link}`}>
                  <div className={classNames(CSS.reminder,'btn--small')}>
                    <Icon symbol={'Clipboard'} library={'files'} size={'16px'} />
                    <span>Copy signup link</span>
                  </div>
                </ClipboardWrapper>
              </div>
            </div>
          ))}
        </div>
        <div className={CSS.controls}>
          <label
            className={'btn-gray mr-10'}
            disabled={pending}
            onClick={e => close(false)}
          >
            Close
          </label>
          <Link to={`/admin/moves?keywords=${encodeURIComponent(keywords)}`} className={'btn-primary'} onClick={handleMovesNavigation}>
            View moves
          </Link>
        </div>
      </div>
    );
}
