import React from 'react';

import { EmptyContent, Button } from '@moved/ui';
import { useModal } from '@moved/services'

import CSS from './styles/FileRequirementList.module.scss';
import { FileRequirementListForm } from './FileRequirementListForm';
import { CreateListModal } from './CreateListModal';
import { LinkListModal } from './LinkListModal';

export const FileRequirementList = ({ task, fileList }) => {
  // hooks
  const Modal = useModal();

  const openLinkModal = () => Modal.open(
    <LinkListModal task={task} />,
    {
        theme: 'grey',
    }
  );

  const openCreateModal = () => Modal.open(
    <CreateListModal task={task} />,
    {
        theme: 'grey',
    }
  );

  return (
    <div className={CSS.content}>
    {!fileList.id ? (
      <>
        <h3 className={CSS.title}>
          Documents list details
        </h3>
        <EmptyContent
          message={'No documents list yet!'}
          actions={(
            <>
              <Button
                size='small'
                color='primary'
                text='Link existing'
                icon={{symbol:'Attachment#1',library:'general'}}
                onClick={openLinkModal}
                className={CSS.link_existing_btn}
              />
              <Button
                size='small'
                color='secondary'
                text='Create new'
                icon={{symbol:'Plus',library:'code'}}
                onClick={openCreateModal}
                className={CSS.new_list_btn}
              />
            </>
          )}
          className='width-full'
        />
      </>

    ) : (
      <FileRequirementListForm task={task} fileList={fileList} />
    )}
    </div>
  );
};
