import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { DynamicForm, AtomSpinner } from '@moved/ui';
import { useModal, useNotify, format } from '@moved/services'

import { linkFileRequirementList, getFileRequirementList, getEligibleFileRequirementLists } from '../actions';
import { useLinkFileRequirementListPending, useFileRequirementListPending, useEligibleFileRequirementLists, useEligibleFileRequirementListsPending } from '../actions/selectors';

import CSS from './styles/LinkListModal.module.scss';

export const LinkListModal = ({ task }) => {
  // hooks
  const dispatch = useDispatch();
  const { close } = useModal();
  const Notify = useNotify();

  // redux
  const eligible = useEligibleFileRequirementLists(task.id);
  const eligiblePending  = useEligibleFileRequirementListsPending();
  const docTaskPending = useFileRequirementListPending();
  const linkPending = useLinkFileRequirementListPending();

  const pending = docTaskPending || linkPending;

  useEffect(() => {
    dispatch(getEligibleFileRequirementLists(task.id))
      .catch(error => Notify.error(format.error(error)));
  // eslint-disable-next-line
  },[]);


  const handleSubmit = (data) => {
    dispatch(linkFileRequirementList(data))
      .then(() => {
        return dispatch(getFileRequirementList(task.id))
          .then(() => close(false))
          .catch(error => Notify.error(format.error(error)));
      })
      .catch(error => Notify.error(format.error(error)));
  };

  if(!eligible || eligiblePending) return <AtomSpinner/>;

  const fields = [
    {
      type: 'hidden',
      name: 'building_task_id',
      value: task.id,
    },
    {
      type: 'itemList',
      name: 'document_task_file_requirement_list_id',
      options: eligible.map(list => ({
        label: list.title,
        value: list.id,
        rightLabel: (
          <span className={CSS.pill}>Used in {list.building_task_count} other task{list.building_task_count > 1 && 's'}</span>
        ),
      })),
      white: true,
      required: "You must select a document list",
    },
  ];


  return (
    <div className={CSS.link}>
      <div className={CSS.title}>
        <h3>Link document list</h3>
      </div>
      <div className={CSS.form}>
        <DynamicForm
          fields={fields}
          formStyle={'underline'}
          id="create-list-form"
          onSubmit={handleSubmit}
        />
      </div>
      <div className={CSS.controls}>
        <label
          className={'btn-gray mr-10'}
          disabled={pending}
          onClick={e => close(false)}
        >
          Cancel
        </label>
        <label
          htmlFor="create-list-form-submit"
          tabIndex="0"
          role="button"
          className={'btn-primary ' + (pending  ? ' loading' : '')}
          disabled={pending}
        >
          Link
        </label>
      </div>
    </div>
  );
}
