import React from 'react';
import { useHistory } from 'react-router-dom';

import { TabSlider } from '@moved/ui';
import { hooks, useUser } from '@moved/services';

import { TaskHeader, DefaultConfiguration } from '../../../components';

import { Instructions } from './Instructions';

import CSS from './styles/Configuration.module.scss';

export const Configuration = ({ task }) => {
  // HOOKS
  const history = useHistory();
  const view = hooks.useQuery('view') || 'general';
  const { hasAbilities } = useUser();

  // Update query string in URL when view is selected
  const setView = (newView) => {
    return history.push({
      ...history.location,
      search: newView ? `view=${newView}` : '',
    });
  };

  const tabs = [
    {
      label: 'General',
      value: 'general',
    },
    hasAbilities('ConfigureHappycoInspection') && {
      label: 'Instructions',
      value: 'instructions',
    },
  ].filter(v=>v);

  return (
    <>
      <TaskHeader task={task}/>

      { tabs.length > 1 && (
        <TabSlider
          className={CSS.slider}
          list={tabs}
          active={view}
          callback={setView}
        />
      )}

      <div className={CSS.view}>
        { view === 'general' && (
          <DefaultConfiguration
            task={task}
            header={false}
          />
        )}
        { view === 'instructions' && (
          <Instructions task={task} />
        )}
      </div>
    </>
  );
};
