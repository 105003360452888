import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { get, find, noop } from 'lodash';

import { request, useNotify } from '@moved/services';

import { rescheduleReservation, rescheduleKeyPickup, getPartnerCalendar } from '../actions';
import { useRescheduleReservationPending, useRescheduleKeyPickupPending} from '../actions/selectors';

import { DynamicForm, AnimatedCheckmark } from '@moved/ui';
import avaCSS from '../styles/SlotEdit.module.scss';

export const RescheduleAppointment = ({ appointment, slot, buildingId, hide, calendarId, type, closeBtn }) => {
  // hooks
  const dispatch = useDispatch();
  const Notify = useNotify();

  // dates
  const date = moment(appointment.start_time).set({hour:0,minute:0,second:0,millisecond:0});
  const now = moment().set({hour:0,minute:0,second:0,millisecond:0});

  // state
  const [submitted, setSubmitted] = useState(false);
  const [slots, setSlots] = useState([]);
  const shouldUseBuildingReservation = ['reserve','move-out-inspection'].includes(type);

  // redux
  const pendingSelector = shouldUseBuildingReservation ? useRescheduleReservationPending : useRescheduleKeyPickupPending;
  const pending = pendingSelector();

  // actions
  const rescheduleAppointment = shouldUseBuildingReservation ? rescheduleReservation : rescheduleKeyPickup;

  // helper functions
  const handleSubmit = (data) => dispatch(rescheduleAppointment(appointment.id,calendarId,{ start_time: data.start_time }))
    .then(resp => {
      setSubmitted(true);
      return dispatch(getPartnerCalendar(calendarId, moment(`${slot.date}T${slot.start}`).startOf('week').format('YYYY-MM-DD')));
    })
    .catch(() => Notify.error('Failed to reschedule the appointment'));

  const _parseSlots = (cal,value) => {
    const slots = find(cal, day => moment(day.date).isSame(value,'day') );
    if(!slots) return setSlots([]);

    const slotOptions = slots.timeslots.reduce((result, slot) => {
      const startTime = moment(`${moment(value).format('YYYY-MM-DD')} ${slot.start}`,'YYYY-MM-DD h:mmA');
      const endTime = moment(`${moment(value).format('YYYY-MM-DD')} ${slot.end}`,'YYYY-MM-DD h:mmA');
      if(slot.is_available) result.push({ label: `${startTime.format('h:mma')} - ${endTime.format('h:mma')}`, value: `${moment(value).format('YYYY-MM-DD')}T${slot.start}` });
      return result;
    }, []);

    return setSlots(slotOptions);
  };

  const _getSlots = (value, setFieldValue = noop, name) => {
    request.get(`/partners/building-calendars/${calendarId}`, {params: { week_of: moment(value).startOf('week').format('YYYY-MM-DD') }})
      .then(resp => {
        setFieldValue('start_time',false,true);
        _parseSlots(get(resp,'data.data.building_calendar.availability'),value);
      })
      .catch(() => Notify.error());
  };

  useEffect(() => {
    _getSlots(date.isBefore(now) ? now.format('YYYY-MM-DD') : date.format('YYYY-MM-DD'))
  // eslint-disable-next-line
  },[]);

  const slotFields = [
    {
      label: 'Full name',
      type: 'text',
      name: 'tenant_name',
      value: `${appointment.firstname} ${appointment.lastname}`,
      readOnly: true,
      half: true,
    },
    {
      label: 'Unit number',
      type: 'text',
      name: 'unit_number',
      value: get(appointment,'unit'),
      readOnly: true,
      half: true,
    },
    {
      label: 'Date',
      type: 'datePicker',
      name: 'date',
      required: true,
      minDate: moment().format('YYYY-MM-DD'),
      value:  date.isBefore(now) ? now.format('YYYY-MM-DD') : date.format('YYYY-MM-DD'),
      onChange: _getSlots,
    },
    {
      label: 'Time slot',
      type: 'select',
      name: 'start_time',
      required: true,
      value: null,
      options: slots,
      readOnly: slots.length > 0 ? false : true,
      placeholder: slots.length > 0  ? null : 'No availability on this day',
    },
  ];

  return (
    <div className={avaCSS.slot_edit}>
      { submitted && (
        <div className={avaCSS.confirm}>
          <AnimatedCheckmark isChecked={true} fast={true} />
          <h4>Reservation added!</h4>
        </div>
      )}

      <div className={avaCSS.slot_edit_title}>
        <span>Reschedule</span>
      </div>
      <div className={avaCSS.slot_edit_form}>
        <DynamicForm
          fields={slotFields}
          formStyle={'underline'}
          id="slot-form"
          onSubmit={handleSubmit}
          className={'mt-20'}
          disabled={pending || slots.length === 0 }
        />
      </div>
      <div className={avaCSS.slot_edit_control}>
        <label
          className={'btn-gray flex-auto mr-10'}
          disabled={pending}
          onClick={pending ? noop : e => hide() }
        >
          Cancel
        </label>
        <label
          htmlFor="slot-form-submit"
          tabIndex="0"
          role="button"
          className={'btn-primary flex-auto ' + (pending  ? ' loading' : '')}
          disabled={pending || slots.length === 0 }
        >
          Update
        </label>
      </div>
    </div>
  );
}
